var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"login"},[_c('h2',{staticClass:"auth-layout--title"},[_vm._v(_vm._s(_vm.$t('menu.login')))]),_c(_setup.AppForm,{attrs:{"hide-cancel":"","loading":_setup.loading},on:{"submit":_setup.onSubmit},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{staticClass:"mt-2",attrs:{"color":"primary","type":"submit","width":"100%","disabled":_setup.loading}},[_vm._v(" "+_vm._s(_vm.$t('btn.login'))+" ")])]},proxy:true}])},[_c(_setup.AppInput,{attrs:{"name":"username","rules":"required","label":_vm.$t('fields.username'),"binds":{ singleLine: true, prependIcon: 'mdi-account' }},model:{value:(_setup.formData.username),callback:function ($$v) {_vm.$set(_setup.formData, "username", $$v)},expression:"formData.username"}}),_c(_setup.AppInput,{attrs:{"name":"password","rules":"required","type":_setup.viewPassword ? 'text' : 'password',"label":_vm.$t('fields.password'),"binds":{
        singleLine: true,
        prependIcon: 'mdi-lock',
        appendIcon: _setup.viewPassword ? 'mdi-eye' : 'mdi-eye-off'
      },"events":{
        'click:append': () => (_setup.viewPassword = !_setup.viewPassword)
      }},model:{value:(_setup.formData.password),callback:function ($$v) {_vm.$set(_setup.formData, "password", $$v)},expression:"formData.password"}})],1),_c('div',{staticClass:"auth-layout--footer"},[_c('router-link',{attrs:{"to":{ name: 'forget-password' }}},[_vm._v(" "+_vm._s(_vm.$t('label.forgetPassword'))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }