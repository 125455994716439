<template>
  <div class="login">
    <h2 class="auth-layout--title">{{ $t('menu.login') }}</h2>
    <app-form hide-cancel @submit="onSubmit" :loading="loading">
      <app-input
        name="username"
        rules="required"
        :label="$t('fields.username')"
        :binds="{ singleLine: true, prependIcon: 'mdi-account' }"
        v-model="formData.username"
      />
      <app-input
        name="password"
        rules="required"
        :type="viewPassword ? 'text' : 'password'"
        :label="$t('fields.password')"
        :binds="{
          singleLine: true,
          prependIcon: 'mdi-lock',
          appendIcon: viewPassword ? 'mdi-eye' : 'mdi-eye-off'
        }"
        :events="{
          'click:append': () => (viewPassword = !viewPassword)
        }"
        v-model="formData.password"
      />

      <template v-slot:actions>
        <v-btn
          color="primary"
          type="submit"
          width="100%"
          class="mt-2"
          :disabled="loading"
        >
          {{ $t('btn.login') }}
        </v-btn>
      </template>
    </app-form>
    <div class="auth-layout--footer">
      <router-link :to="{ name: 'forget-password' }">
        {{ $t('label.forgetPassword') }}
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { useStore, useRouter } from '@utils/composition-shim'
import { useI18n } from 'vue-i18n-bridge'
import AppForm from '@components/AppForm'
import AppInput from '@components/AppInput'
import { swalError, swalWarn } from '@utils/swal'

const formData = reactive({})
const loading = ref(false)
const viewPassword = ref(false)

const store = useStore()
const router = useRouter()
const { t } = useI18n()

// Methods
const onSubmit = () => {
  loading.value = true
  store
    .dispatch('login', formData)
    .then(() => {
      router.push({ name: 'home' })
    })
    .catch((error) => {
      if (error.status === 402) {
        if (error.data.code === 'subscription_required') {
          swalWarn(
            t,
            t('alert.subscriptionRequired.text'),
            t('alert.subscriptionRequired.title')
          )
        } else if (error.data.code === 'deactivated_user') {
          swalWarn(
            t,
            t('alert.deactivatedUser.text'),
            t('alert.deactivatedUser.title')
          )
        } else {
          swalWarn(
            t,
            t('alert.subscriptionError.text'),
            t('alert.subscriptionError.title')
          )
        }
      } else if (error.status === 401) {
        swalWarn(t, t('alert.errorLogin.text'), t('alert.errorLogin.title'))
      } else {
        swalError(t)
      }
    })
    .finally(() => {
      loading.value = false
    })
}
</script>
